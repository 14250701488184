@import './src/styles/main';

.row {
  height: 10px;
  box-sizing: border-box;
  background-color: $white;

  .cell {
    width: 288px;
    box-sizing: border-box;
    padding: 8px 4px;
    border-bottom: none;
  }

  .input {
    width: 288px;
    height: 36px;
  }

  .iconCell {
    width: auto;
    padding: 16px 20px 16px 2px;
    vertical-align: top;
  }

  .icon {
    width: 24px;
    height: 24px;
    box-sizing: border-box;
    padding: 0;
    border: 1px solid $light-gray;
    background-color: $white;
  }
}

.grayRow {
  background-color: $light-gray00;
}
