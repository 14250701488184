@import './src/styles/main';

.root {
  display: flex;
  height: 100%;
  flex-direction: column;
}

.guide {
  margin-top: 16px;
}

.header {
  margin-top: 24px;
}

.filterWrapper {
  z-index: 4;
  padding-bottom: 6px;
  background-color: $white;
}

.listWrapper {
  margin-top: 2px;
}

.listDivider {
  margin: 40px 0 16px;
}

.pagination {
  margin-bottom: 34px;
}
