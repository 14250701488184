@import './src/styles/color';

.buttonWrapper {
  display: flex;
  justify-content: center;
  padding: 16px;

  .button {
    width: 100%;
    border: 1px solid $light-gray;
    border-radius: 20px;

    :first-child {
      margin-right: 4px;
    }
  }
}
