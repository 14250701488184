@import './src/styles/color';

.textField {
  display: flex;
  width: 400px;
  height: 48px;
  box-sizing: border-box;
  align-items: center;
  padding: 0 8px;
  border: 1px solid $light-gray04;
  border-radius: 4px;
  background: $white;
  cursor: text;
  outline: none;

  &:focus-within {
    background: $white-gray;
  }
}

.input {
  width: 100%;
  height: 19px;
  border: none;
  margin-right: 14px;
  background: transparent;
  font-size: 16px;
  line-height: 19px;
  outline: none;

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    margin: 0;
    -webkit-appearance: none;
  }

  &[type='number'] {
    -moz-appearance: textfield;
  }
}

.cancelButton {
  width: 16px;
  height: 16px;
  svg {
    width: 16px;
    height: 16px;
  }
}
