@import './src/styles/main';

.root {
  display: flex;
  height: 100vh;
  box-sizing: border-box;
  flex-direction: column;
  flex-grow: 1;
  padding: 0 32px;
  overflow-y: auto;
}

.breadcrumb {
  position: sticky;
  z-index: 2;
  top: 0;
  height: $breadcrumb-height;
  background-color: $white;
}

.header {
  margin-bottom: 28;
}

.tab {
  position: sticky;
  z-index: 2;
  top: $breadcrumb-height;
  background-color: $white;
}

.tabContents {
  height: $tab-content-height;
}

.modal {
  align-items: flex-start;
  justify-content: flex-end;
}

.message {
  width: 100%;
  margin-top: 200px;
  text-align: center;
}
