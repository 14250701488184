@import 'src/styles/main';

.root {
  display: flex;
  align-items: center;
}

.labelContainer {
  display: flex;
  width: 200px;
}

.label {
  font-size: 14px;
  font-weight: 700;
}

.textFieldWrapper {
  .error {
    border: 1px solid $error;
  }
}

.textField {
  width: 200px;
  height: 44px;
  border-radius: 12px;
  margin: 4px 0 2px;

  input {
    font-size: 14px;
  }
}

.errorContainer {
  height: 20px;
}

.errorMessage {
  color: $error;
  font-size: 12px;
}

.deleteButton {
  display: flex;
  width: 120px;
  height: 32px;
  border: $red 1px solid;
  border-radius: 8px;
  background-color: $pink02;
  color: $red;
  font-size: 12px;
  font-weight: 400;
  text-wrap: nowrap;
  &:hover {
    background-color: $pink01;
  }

  .icon {
    display: flex;
    margin-top: 4px;
  }

  .deleteButtonText {
    margin-right: 4px;
  }
}

.radioButtonWrapper {
  display: flex;
  align-items: center;
  margin: 0 12px 4px;
  text-wrap: nowrap;
}
