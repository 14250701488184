@import './src/styles/main';
@import '../../variables';

.field {
  height: $filter-content-height + 4px !important;
  padding: 2px;
  margin-right: 20px;

  input {
    font-size: 16px;
    line-height: 16px;
  }
}

.select {
  width: auto !important;
  margin-right: 8px;

  &:last-child {
    margin-right: unset;
  }
}

.checkContainer {
  position: relative;
  width: 120px;
  height: $filter-content-height - 8px;
  align-items: center;
  > div {
    > div {
      height: $filter-content-height - 4px;
    }
  }
}
.checkPosition {
  position: absolute;
  top: 0px;
  overflow: hidden;
  padding: 0px;
  margin: 0px;
}

.popupChild {
  height: $filter-content-height - 8px;
  padding-left: 8px;
  line-height: $filter-content-height - 8px;
}
.smallPopupChild {
  width: 100px;
}
.biggerPopupChild {
  width: 170px !important;
}

.blue {
  border-color: $blue;
  color: $blue;
  font-weight: 700;
}

.label {
  position: absolute;
  top: -20px;
  font-size: 12px;
}
