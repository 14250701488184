@import './src/styles/main';

.container {
  display: flex;
  height: 4rem;
  flex-flow: row wrap;
  align-items: center;
  justify-content: flex-start;
  background-color: $white;
}

.total {
  margin-right: 4px;
  font-weight: bold;
}

.item {
  margin-right: 32px;
}

.search {
  width: 200px !important;
}
