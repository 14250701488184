@import './src/styles/main';
@import '../../variables';

.breadcrumb {
  position: sticky;
  z-index: 1;
  top: 0;
  min-width: $table-min-width;
  height: $breadcrumb-height;
  background-color: $white;
}

.title {
  font-size: 24px;
}

.filterWrapper {
  position: sticky;
  z-index: 1;
  top: $breadcrumb-height;

  // FIXME: width: 100vwで指定をすると、画面を小さくしたときにテーブル領域から要素がはみ出てしまう
  min-width: $table-min-width;
  height: $filter-height;
  background-color: $white;
}

.mainContent {
  padding: 4px;
  margin-bottom: 200px;
}

.mainContent table thead {
  position: sticky;

  // NOTE: BreadcrumbとfilterWrapperのHeightを足した値の場所で固定する
  top: $breadcrumb-height + $filter-height;
}

.header {
  display: flex;
  height: 4rem;
  flex-flow: row wrap;
  align-items: center;
  justify-content: flex-start;
  padding: 0 1rem;
  background-color: $white;
}

.count {
  margin-right: 12px;
}

.bottom {
  position: fixed;
  bottom: 0;
  width: calc(100% - $sidebar-width - 50px);
  padding: 8px;
  border-top: 1px solid $light-gray;
  background-color: white;
  text-align: right;
  button {
    margin-right: 20px;
  }
}
