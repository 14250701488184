@import 'src/styles/color';
@import './src/styles/main';

.container {
  height: 100vh;

  .breadcrumb {
    position: sticky;
    z-index: 1;
    top: 0;
    height: $breadcrumb-height;
    background-color: $white;
  }

  .filterBar {
    display: flex;
  }

  .title {
    display: flex;
    margin-bottom: 36px;
    font-size: 24px;
  }

  .actions {
    display: flex;
  }

  .updateButton {
    min-width: 105px;
  }

  .updatedAtText {
    font-weight: bold;
  }

  .updatedAtTime {
    margin-left: 12px;
  }

  .filterWrapper {
    position: sticky;
    z-index: 1;
    top: $breadcrumb-height;
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    background-color: $white;
  }

  .button {
    width: auto;
    border-color: $black300;
    margin-right: 20px;

    &:hover {
      border-color: $black300;
    }
  }

  .date {
    margin-right: 40px;
  }

  .tableWrapper {
    height: 75%;
  }

  .table {
    height: 95%;
  }

  .guide {
    margin-top: 16px;
  }
}

.centeringLoading {
  height: 50%;
}
