@import './src/styles/main';

.addButtonWrapper {
  display: flex;
  justify-content: center;
  padding: 16px;

  .button {
    width: 36px;
    height: 36px;
    border: solid $black200;
  }
  svg {
    width: 20px;
    height: 20px;
  }
}

.icon {
  width: 16px;
  padding: 0;
}
