@import './src/styles/main';

.row {
  background-color: $white;
  white-space: pre;
}

.grayRow {
  background-color: $light-gray00;
}

.cell {
  height: 56px;
  box-sizing: border-box;
  padding: 10px 6px;
  border-bottom: none;

  .small {
    width: 64px;
  }

  .medium {
    width: 108px;
  }

  .large {
    width: 180px;
  }

  .input {
    width: 288px;
    height: 36px;
  }
}

.noPaddingRight {
  padding-right: 0;
}
