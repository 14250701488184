@import './src/styles/main';

$each-category-padding-height: 20px;
$title-height: 20px;

.root {
  width: 100%;
  overflow-y: scroll;
}

.eachCategory {
  width: 95%;
  box-sizing: border-box;
  margin-bottom: 24px;
}

.message {
  height: 180px;
  box-sizing: border-box;
  padding: 4px 12px;
  border: solid 1px #d6d3d0;
  border-radius: 10px;
  background-color: #edebe8;
  overflow-y: scroll;
}

.messageBody {
  font-size: 14px;
  font-weight: 400;
  overflow-y: auto;
  white-space: pre-wrap;
}

.title {
  display: flex;
  height: $title-height;
  align-items: center;
  padding-bottom: 4px;
  margin-bottom: 4px;

  .icon {
    width: 20px;
    height: $title-height;

    &.colorBlack {
      path {
        fill: $black;
      }
    }
  }
  .text {
    margin-left: 4px;
    font-size: 12px;
    font-weight: 700;
    line-height: $title-height;
  }
}
