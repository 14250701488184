@import './src/styles/main';

.root {
  width: auto;
  height: 100%;
  overflow-x: auto;
}

.table {
  width: auto;
}

.icon {
  width: auto;
  margin-top: 4px;
  & > button {
    pointer-events: none;
  }
  svg {
    width: 24px;
    height: 24px;
  }
}

.blue {
  path {
    fill: $blue;
  }
}

.row {
  height: 44px;
  background-color: $white;
}

.activeRow {
  cursor: pointer;
}

.text {
  span {
    color: $black;
    font-weight: 600;
  }
}

.number {
  text-align: right;
  span {
    color: $black;
    font-weight: 600;
  }
}

.center {
  text-align: center;
}

.badge {
  & > div {
    padding: 0px 6px;
  }
}
