@import './src/styles/main';
@import 'src/styles/main';

.datePickerContainer {
  position: relative;
  display: flex;
  overflow: hidden;
  height: 44px;
  box-sizing: border-box;
  align-items: center;
  border: 1px solid;
  border-color: $light-gray;
  border-radius: 12px;
  &:hover {
    background-color: $light-gray02;
  }
  & > svg {
    margin-left: -28px;
  }
}

.datePicker {
  display: flex;
  width: 100%;
  height: 48px;
  align-items: flex-start;
  justify-content: center;
}

.cancelButton {
  position: absolute;
  right: 10px;
}

.error {
  border: 1px solid $error;
}
