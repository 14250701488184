@import './src/styles/main';

.breadcrumbs {
  height: $breadcrumb-height;
}

.title {
  font-size: 24px;
}

.store {
  font-size: 20px;
}

.name {
  margin-left: 20px;
}

.code {
  margin-left: 24px;
}
