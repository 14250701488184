.text {
  display: block;
}

.ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.unit {
  display: inline;
  margin-left: 4px;
  font-size: 10px;
}
