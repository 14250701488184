@import './src/styles/main';

.select {
  font-size: 14px;
  font-weight: 400;
}

.optgroup {
  padding: 12px 16px;
  line-height: 12px;
}

.option {
  min-height: 43px; // FIXME: option が空文字の時、高さが壊れてしまうため暫定対応
  padding: 12px 16px;
}

.indent {
  padding-left: 40px;
}

.icon {
  margin-right: 8px;
}

.placeholder {
  display: none;
}

.menuPaper {
  box-sizing: border-box;
  padding: 0;
  border-radius: 6px;
  box-shadow: 0px 4px 14px $black100;
}

.selected {
  background-color: $light-gray;
}
