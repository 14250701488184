@import './src/styles/main';
@import '../../variables';

.loadingContainer {
  width: calc(100vw - $sidebar-width);
  height: 500px;
}

.noContent {
  width: calc(100vw - $sidebar-width);
  height: 500px;
  padding-top: 200px;
  padding-left: 48px;
}

.hidden {
  height: 44px;
  visibility: hidden;
}

.warningUpdate {
  color: $red;
}

.filterWrapper {
  position: sticky;
  z-index: 1;
  top: 0px;

  // NOTE: Update画面にはsidebarがないため
  // FIXME: width: 100vwで指定をすると、画面を小さくしたときにテーブル領域から要素がはみ出てしまう
  min-width: $table-min-width + $sidebar-width;
  background-color: $white;
}

.title {
  margin-top: 20px;
  margin-bottom: 20px;
  font-size: 24px;
}

.mainContent {
  width: 800px;
  margin-bottom: 80px;
}

.mainContent table thead {
  position: sticky;
  z-index: 1;
  top: 64px;
}

.bottom {
  position: fixed;
  bottom: 0;
  width: 100%;
  padding: 8px;
  border-top: 1px solid $light-gray;
  background-color: $white;
  text-align: right;
  button {
    margin-right: 20px;
  }
}

.guide {
  margin-top: 16px;
}
