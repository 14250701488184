@import './src/styles/main';

.date {
  margin-left: 4px;
}

.fontInfo {
  font-size: 14px;
  font-weight: 400;

  @media (width <= $breakpoint-windows) {
    font-size: 12px;
  }
}

.reportMessage {
  display: flex;
  align-self: center;
  color: $gray03;
}

.messageBody {
  margin-bottom: 8px;
  overflow-y: auto;
  white-space: pre-wrap;
}
