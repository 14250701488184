@import 'src/styles/color';

.row {
  height: 44px;
  cursor: pointer;
  white-space: nowrap;

  &:hover {
    background-color: $black50;
  }
}

.cell {
  box-sizing: border-box;
  padding: 0px 12px;
  border-bottom: solid 1px $light-gray;
}

.white {
  background-color: $white;
}

.gray {
  background-color: $light-gray03;
}

.rightCellText {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
}

.leftCellText {
  display: flex;
  align-items: flex-end;
}

.title {
  font-size: 14px;
  font-weight: 400;
}

.unit {
  margin-left: 4px;
  font-size: 11px;
}
