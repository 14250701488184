@import './src/styles/color';

.input {
  width: 100%;
  height: 36px;
  input {
    font-size: 13px;
  }
}
.error {
  margin-top: 2px;
  color: $error;
}
