@import './src/styles/main';

.root {
  position: relative;
}

.container {
  position: absolute;
  display: flex;
  align-items: center;
}

.label {
  margin-right: 12px;
  margin-left: 2px;
  font-size: 14px;
  font-weight: 700;
}

.badge {
  display: flex;
  align-items: center;
  padding: 1px 8px !important;
  font-size: 12px;
  line-height: 1;
  & > span {
    font-weight: 700;
  }
}

.textField {
  width: 100% !important;
  height: 44px;
  border: 1px solid $light-gray !important;
  border-radius: 12px !important;
  margin-bottom: 2px;
  & > input {
    font-size: 14px;
  }
}

.counterContainer {
  height: 24px;
  margin-bottom: 2px;
}

.counter {
  display: flex;
  justify-content: flex-end;
  color: $gray03;
  font-size: 14px;
  font-weight: 500;
  & > span {
    margin-top: 4px;
    margin-left: 2px;
    font-size: 10px;
    font-weight: 600;
  }
}

.errorMessage {
  color: $error;
  font-size: 12px;
}

.errorContainer {
  height: 20px;
}

.error {
  border: 1px solid $error !important;
}
