@import './src/styles/main';

.filterContainer {
  display: flex;
  width: 100%;
  align-items: center;

  .textFieldWrapper {
    width: 136px;
    margin-right: 15px;
  }

  input {
    font-size: 12px;
  }
}

.totalCount {
  margin-right: 12px;
}

.checkContainer {
  position: relative;
  display: flex;
  align-items: center;

  .label {
    margin: 0 4px 0 12px;
    font-size: 12px;
  }
}

.popupChild {
  min-width: 100px;
  padding-left: 8px;
  line-height: 24px;
}

.blue {
  border-color: $primary;
  color: $primary;
  font-weight: 700;
}

.ngCheck {
  width: 134px;
}

.ngCheckText {
  width: 100%;
  margin-right: 8px;
  span {
    font-size: 14px;
  }
}
