@import './src/styles/color';

.container {
  display: flex;
  height: 100vh;
  box-sizing: border-box;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: $gray05;
}

.wrapper {
  display: flex;
  width: 640px;
  height: 460px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 68px;
  background-color: $white;
}

.guide {
  width: 400px;
}

.logo {
  margin-bottom: 52px;
}

.message {
  margin-bottom: 24px;
  white-space: pre-wrap;
}
