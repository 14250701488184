@import 'src/styles/main';

.content {
  display: flex;
  height: 32px;
  align-items: center;
  & > h3 {
    font-size: 14px;
    font-weight: 800;
  }
}

.label {
  width: 160px;
}

.value {
  font-size: 14px;
}

.note {
  font-size: 14px;
  white-space: pre-wrap;
}

.cardDivider {
  border-bottom: 1.5px solid $light-gray;
  margin: 12px 0;
}

.datesContainer {
  display: flex;
  width: 80%;
  height: 32px;
  & > div {
    display: flex;
    width: 50%;
    align-items: center;
  }
}

.dateLabel {
  margin-right: 20px;
  font-size: 14px;
  font-weight: 800;
}

.registerBadge {
  padding: 1px 6px;
  margin-left: 8px;
  & > span {
    font-size: 10px;
  }
}
