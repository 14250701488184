@import './src/styles/main';

.row {
  height: 56px;
  box-sizing: border-box;
  background-color: $white;
}

.grayRow {
  background-color: $light-gray00;
}

.cell {
  box-sizing: border-box;
  padding: 0 2px;
  border-bottom: none;

  .medium {
    width: 60px;
  }

  .large {
    width: 172px;
  }

  .select {
    height: 36px;
  }

  .icon {
    width: 24px;
    height: 24px;
    border: 1px solid $light-gray;
    background-color: $white;
  }
}
