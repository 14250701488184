@import './src/styles/main';
@import '../../variables';

.root {
  position: relative;
  height: $info-height;
  box-sizing: border-box;
  padding: $below-header-margin 0px 10px;
  border-bottom: solid $category-border-size $category-border-color;
  margin: 0px 20px;
}

.title {
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;

  @media (width <= $breakpoint-windows) {
    font-size: 14px;
  }
}

.rightAbove {
  position: absolute;
  top: $below-header-margin;
  right: 4px;
}

.pathToDetail {
  display: flex;
  align-items: center;
  color: $blue;
  font-size: 12px;
  font-weight: 700;
  line-height: 20px;

  @media (width <= $breakpoint-windows) {
    font-size: 10px;
  }
}

.icon {
  width: 15px !important;
  height: 15px !important;
  margin-left: 4px;

  @media (width <= $breakpoint-windows) {
    width: 13px !important;
    height: 13px !important;
    margin-left: 2px;
  }
}

.content {
  font-size: 14px;
  font-weight: 400;

  @media (width <= $breakpoint-windows) {
    font-size: 12px;
  }
}

.eachInfo {
  max-width: $memo-width - 44px;
  line-height: 24px;

  @include ellipsis;
}

.code {
  display: inline-block;
  width: 70px;
  color: $black;
  font-weight: 700;

  &.long {
    width: 140px;

    @media (width <= $breakpoint-windows) {
      width: 120px;
    }
  }
}
