@import './src/styles/color';

.title {
  margin-bottom: 24px;
  font-size: 20px;
  font-weight: 700;
}

.textField {
  width: 420px;
  height: 60px;
  border: 2px solid $gray04;
  border-radius: 20px;
}

.textFieldError {
  border: 1px solid $red !important;
}

.errorContainer {
  height: 76px;
}

.emailError {
  margin-top: 10px;
  color: $red;
  font-size: 16px;
  line-height: 20px;
}

.buttonWrapper {
  display: flex;
  justify-content: center;
}

.button {
  width: 224px;
  height: 56px;
  border-radius: 20px;
  margin-top: 10px;
}
