@import './src/styles/color';

.button {
  position: absolute;
  bottom: 20px;
  left: 468px;
  width: 188px;
  height: 42px;
  padding: 4px;
  border-radius: 8px;
  background-color: $white;

  &.active {
    border: 1px solid $black200;
    color: $black;
  }

  &.deactivate {
    border: 1px solid $white-gray;
    color: $gray-hover;
  }
}

.title {
  font-size: 1rem;
  font-weight: 700;
}
