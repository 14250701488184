@import 'src/styles/main';

.textFieldContainer {
  padding-top: 4px;
}

.flexItemContainer {
  width: 250px;
  margin-right: 32px;
  margin-bottom: 4px;

  .error {
    border: 1px solid $error;
  }
}

.uneditableItemContainer {
  display: flex;
  & > div {
    width: 250px;
    margin-right: 32px;
  }
  p {
    margin: 8px 0 24px;
  }
}

.label {
  font-size: 14px;
  font-weight: 700;
}

.dateLabel {
  position: relative;
  margin-bottom: 4px;
  font-size: 14px;
  font-weight: 700;
}

.labelAndDigitContainer {
  display: flex;
  width: 200px;
}

.textFieldWrapper {
  .error {
    border: 1px solid $error;
  }
}

.textField {
  width: 200px;
  height: 44px;
  border-radius: 12px;
  margin: 4px 0 2px;
  input {
    font-size: 14px;
  }
}

.badge {
  height: 16px;
  align-items: center;
  padding: 1px 8px;
  border-radius: 20px;
  margin-left: 8px;
  & > span {
    font-weight: 700;
  }
}

.radioGroup {
  margin: 4px 0;
}
