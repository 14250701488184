@import './src/styles/main';

.container {
  display: flex;
  overflow: scroll;
  max-height: 250px;
  flex-direction: column;
  padding: 12px;
  border: solid $light-gray;
  border-radius: 4px;
  background-color: white;
}

.label {
  span {
    font-weight: 600 !important;
  }
}

.icon {
  width: 20px;
  height: 20px;
}

.iconSpace {
  width: 4px;
}
