.root {
  position: relative;
  display: flex;
  width: 100%;
  align-items: center;
  gap: 24px;
}

.title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 24px;
  line-height: 27px;
}

.name {
  overflow: hidden;
  max-width: 340px;
  cursor: pointer;
  text-overflow: ellipsis;
  word-wrap: break-word;
}

.code {
  flex-shrink: 0;
  margin-right: 12px;
}

.registerIndex {
  margin-left: 16px;
}

.resultWrapper {
  display: flex;
  flex-direction: row;
  vertical-align: middle;
}

.result {
  padding: 0 8px;
  margin-left: 16px;
}

.icon {
  margin-right: 6px;
}

.date {
  font-size: 14px;
}

.centerLine {
  display: flex;
  min-width: 0;
  flex: 1;
  align-items: center;
  justify-content: space-between;
  margin-left: 52px;

  .statusAndTitle {
    display: flex;
    min-width: 0;
    align-items: center;
  }
}

.rightLine {
  display: flex;
  margin-left: 24px;
}

.salesReportDate {
  display: flex;
  align-items: center;
}

.salesReportDateTitle {
  margin-right: 4px;
  font-size: 14px;
  font-weight: 700;
}

.centerLineLeftMargin {
  margin-left: 408px;
}

.badge {
  padding: 3px 6px;
  margin: 0 8px;
}

.tenantRegister {
  display: flex;
  height: 40px;
  align-items: center;
  margin-right: 40px;
  margin-left: auto;
}

.registerNumberWrapper {
  display: flex;
  min-width: 100px;
  align-items: center;
  gap: 4px;
}

.registerNumberTitle {
  font-size: 18px;
  font-weight: 700;
  line-height: 20px;
}

.registerNumber {
  font-size: 18px;
  font-weight: 400;
  line-height: 20px;
}

.registerBadge {
  height: 20px;
  padding: 0 8px;
  margin-right: 8px;
  & > span {
    font-size: 10px;
  }
}
