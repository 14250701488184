.dialog {
  width: 334px;
  height: 204px;
  padding: 49px 32px;
  border-radius: 25px;
}

.title {
  width: 250px;
  padding: 10px 36px 20px 36px;
  font-size: 14px;
  font-weight: 700;
  text-align: center;
  white-space: pre-wrap;
}

.content {
  width: 250px;
  padding: 0px 36px 20px 36px;
  font-size: 14px;
  text-align: center;
  white-space: pre-wrap;
}

.progress {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 36px 30px 36px;
}
