@import './src/styles/main';

.container {
  width: 100%;
  cursor: pointer;
  font-size: 13px;
}

.box {
  padding: 4px;
  border: 1px solid $black200;
  border-radius: 8px;
  line-height: 2rem;
  &:hover {
    background-color: $black100;
  }
}

.basePopup {
  z-index: 10;
}
