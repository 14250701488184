@import './src/styles/color';

.header {
  display: grid;
  justify-content: space-between;
  padding: 0 14px 0 62px;
  color: $black400;
  grid-template-columns: 2fr 3fr;
  line-height: 19px;
}

.opaHeader {
  display: grid;
  justify-content: space-between;
  padding: 0 14px 0 62px;
  color: $black400;
  grid-template-columns: 1fr 3fr;
  line-height: 19px;
}

.leftColumn {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
}

.opaLeftColumn {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
}

.text {
  text-align: left;
}

.divider {
  margin-left: 55px;
}
