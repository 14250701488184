@import './src/styles/color';

.container {
  height: 100vh;
  max-height: 100%;
}

.header {
  margin-bottom: 20px;
}

.count {
  min-width: 76px;
  margin-top: 8px;
  font-weight: 700;
}

.popup {
  position: absolute;
  bottom: 4px;
  left: 240px;
  width: 220px;
  border-radius: 16px;
  margin: 16px 0;
  background: $white;
  color: $black;
  font-weight: 700;
  text-align: center;
}

.disabled {
  border: 1px solid $white-gray;
  color: $gray-hover;
  cursor: default;
}

.updateStatusMenu {
  display: 'flex';
  padding: '16px 0px';
  border-radius: 16px;
  text-align: 'center';
}

.finalizedReportButton {
  position: absolute;
  right: 30px;
  bottom: 20px;
  width: 240px;
}

.tableWrapper {
  height: 75%;
}

.table {
  max-height: 90%;
}

.footer {
  height: 48px;
  margin: 4px;
}

.guide {
  margin-top: 16px;
}

.filterBar {
  display: flex;
  flex-wrap: nowrap;
  margin-bottom: 16px;
}

.reload {
  min-width: 105px;
}

.date {
  min-width: 230px;
}
