@import 'src/styles/main';

.root {
  display: flex;
  align-items: start;
  justify-content: start;
}

.card {
  padding-bottom: 12px;
}

.registerRow {
  padding-top: 4px;
}

.addButton {
  padding: 0;
  margin-top: 8px;
  &:hover {
    background-color: transparent;
  }

  .addButtonText {
    align-self: end;
    margin-left: 4px;
    color: $black;
    font-size: 14px;
    font-weight: 700;
  }

  .addIcon {
    padding: 2px;
    border: 1px solid $light-gray;
    border-radius: 50%;
  }
}
