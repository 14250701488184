.badge {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
}

.obliqueLine {
  margin-right: 4px;
  margin-left: 4px;
}

.change {
  margin-bottom: 30px;
  margin-left: 4px;
  font-size: 14px;
  font-weight: 700;
  text-align: center;
}
