@import 'src/styles/main';

.root {
  padding: 16px 0 52px;
}

.container {
  display: flex;
}

.flexContainer {
  display: flex;
  align-items: center;
  justify-content: start;
}

.leftColumn {
  min-width: 600px;
  flex: 6;
}

.rightColumn {
  min-width: 400px;
  flex: 4;
}

.leftCard {
  margin-right: 24px;
  margin-bottom: 32px;
}

.rightCard {
  margin-bottom: 32px;
}

.cardTitle {
  & > h2 {
    font-size: 14px;
  }
}

.footer {
  position: absolute;
  right: 0;
  bottom: 0;
  width: calc(100% - $sidebar-width);
  background-color: white;
}

.divider {
  width: 100%;
}

.footerButton {
  height: 40px;
  margin: 8px 4px;
}

.value {
  font-size: 14px;
}

.footerButtonWrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-right: 20px;
}

.errorContainer {
  height: 20px;
}

.errorMessage {
  color: $error;
  font-size: 12px;
}

.error {
  border: 1px solid $error;
}

.digit {
  margin-left: auto;
  color: $gray03;
  font-size: 10px;
  .digitNumber {
    margin-right: 2px;
    font-size: 14px;
  }
}
