@import './src/styles/main';

.root {
  overflow: hidden;
  border: 2px solid $light-gray;
  border-radius: 20px;
  background-color: white;
}

.card {
  display: flex;
  align-items: center;
  padding: 12px 28px;
  border-bottom: 2px solid $light-gray;
  background-color: $gray01;
}

.subtitle {
  margin-left: 8px;
  font-weight: 400;
}

.unit {
  color: $black300;
}

.body {
  margin: 12px 28px 16px;
}
