@import './src/styles/main';

$tooltip-margin-left: -4px;

.tooltip {
  max-width: 240px;
  margin: 0;
  background-color: $black430;
  font-size: 12px;
  font-weight: 400;
  line-height: 14px;
}

.placementTop {
  // NOTE: Material-UI tooltip の default {margin-bottom: 14px} を top に向くように
  margin: 28px 0 0 $tooltip-margin-left;
}

// NOTE: 上部にツールチップを表示する余裕がない場合、自動で下部に移動してしまうため、その時用のスタイル
.placementBottom {
  margin: 0 0 0 $tooltip-margin-left;
}
