@import './src/styles/main';

.container {
  display: flex;
  height: 4rem;
  flex-flow: row wrap;
  align-items: center;
  justify-content: flex-start;
  background-color: $white;
}

.item {
  margin-left: 30px;
  &:first-child {
    margin-left: 0;
  }
}
