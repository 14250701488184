// このファイルはTenantCreateTableとTenantUpdateTableの二つから参照されている
@import './src/styles/main';

.root {
  width: -moz-fit-content;
  width: fit-content;

  .tableWrapper {
    width: -moz-fit-content;
    width: fit-content;
    .table {
      width: initial;
    }
  }
}

.addRowButtonWrapper {
  width: 220px;
  margin: 0 auto;
}

.selectCell {
  padding-right: 4px;
}

.contractorCodeCell {
  padding-left: 1px;
}

.textCell {
  padding-left: 16px;
}
