@import './src/styles/main';
@import './src/styles/variables';

.button {
  display: flex;
  height: $filter-content-height;
  align-items: center;
  padding-left: 4px;
  border: 1px solid $gray03;
  border-radius: 10px;
  margin-top: 4px;
  margin-right: 10px;
  background: none;
  cursor: pointer;
  font-weight: bold;
}

.icon {
  margin-right: 4px;
}

.update {
  padding-right: 4px;
  font-weight: 400;
}
