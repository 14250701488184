@import './src/styles/main';

.statusDeactive {
  color: $gray03;
}

.statusActive {
  color: $green;
}

.row {
  height: 52px;
}
