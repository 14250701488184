.root {
  display: flex;
  width: 100%;
  height: 28px;
  align-items: center;
  justify-content: center;
}

.icon {
  width: 20px !important;
  height: 20px !important;
}
