@import './src/styles/color';

.storeFilterButton {
  padding: 1px 8px !important;
}

.error {
  margin-top: -12px;
  margin-right: 20px;
  color: $red;
  text-align: right;
}
