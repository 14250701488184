@import './src/styles/main';

$timeout: 500;
$rowHeight: 44px;

.expandIcon {
  height: 8px;
  margin: auto 0;
  color: $black;
  transition: #{$timeout}ms;
}

.expanded {
  transform: rotate(90deg);
}

.row {
  height: $rowHeight;
  cursor: pointer;
  white-space: nowrap;
}

.parentRow {
  &:hover {
    background-color: $black50;
  }
}

.childRow {
  height: 0;
  transition: #{$timeout}ms;
}

.expandedChildRow {
  height: $rowHeight;
}

.cell {
  width: 184px;
  max-width: 184px;
  box-sizing: border-box;
  padding: 0 8px;
  border-bottom: solid 1px $light-gray;
  transition: #{$timeout}ms;
  white-space: pre-wrap;
  word-wrap: break-word;
}

.firstCell {
  padding-left: 24px;
}

.hideCell {
  border-bottom: none;
}

.haveChildCell {
  padding-left: 0;
}

.typography {
  font-size: 15px;
  line-height: 17px;
  word-break: break-word;
}

.parentTitle {
  font-weight: 900;
}

.errorText {
  color: $red;
}

.iconTooltip {
  display: flex;
}

.iconWrapper {
  display: flex;
  align-items: center;
  margin-left: 8px;
  cursor: pointer;
}

.iconAndTypography {
  display: flex;
  width: 160px;
  max-width: 160px;
}

.wrapper {
  display: flex;
}
