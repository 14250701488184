@import './src/styles/main';

.dialog {
  width: 640px;
  height: 420px;
  border-radius: 12px;
}

.backDrop {
  background-color: $black430;
}

.container {
  width: 100%;

  .header {
    display: flex;
    height: 32px;
    align-items: center;
    justify-content: space-between;

    .title {
      font-size: 20px;
      font-weight: 700;
      white-space: pre-wrap;
    }

    .closeButton {
      width: 20px;
      height: 20px;
      padding: 0;
      margin: 0;
    }
  }

  .textField {
    width: 92%;
    margin: 0 auto;
  }

  .confirmWrapper {
    .confirmMessage {
      margin-left: 24px;
      font-size: 16px;
      font-weight: 700;
      white-space: pre-wrap;
    }

    .confirmNotificationWrapper {
      overflow: auto;
      height: 180px;
      box-sizing: border-box;
      padding: 8px;
      border: solid 1px #d6d3d0;
      border-radius: 10px;
      margin: 24px;
      background-color: #edebe8;

      .confirmInputNotification {
        font-size: 14px;
        font-weight: 400;
        white-space: pre-wrap;
      }
    }
  }

  .action {
    display: flex;
    padding: 0px;
    margin: 32px 16px;
    text-align: right;

    .backButton {
      width: 168px;
      border-radius: 12px;
      margin-right: 16px;
    }

    .agreeButton {
      width: 168px;
      border-radius: 12px;
    }
  }
}
