@import './src/styles/main';

.container {
  display: flex;
  width: $sidebar-width;
  height: 100vh;
  flex-direction: column;
  background-color: $gray01;
}

.topContainer {
  display: flex;
  box-sizing: border-box;
  flex-direction: column;
  flex-grow: 1;
  padding: 32px 16px 0;
}

.bottomContainer {
  display: flex;
  padding: 36px 12px 12px;
}

.topTableOfContents {
  flex-grow: 1;
}

.logoButton {
  width: 64px;
  height: 24px;
  padding: 0;
  border: none;
  margin: 0;
  margin-bottom: 36px;
  background-color: inherit;
  cursor: pointer;
  outline: none;
}

.logoButton:hover {
  background-color: inherit;
}

.logo {
  width: 100%;
  height: 100%;
}

.logoutButton {
  display: flex;
  align-items: flex-end;
  border-radius: 10px;
  cursor: pointer;
}

.env {
  position: absolute;
  top: 0;
  left: 10px;
}
