@import './src/styles/color';

.root {
  display: flex;
  height: 100vh;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0;
  background-color: $gray05;
}

.body {
  display: flex;
  width: 640px;
  height: 460px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 68px;
  background-color: $white;
}

.logo {
  margin-bottom: 52px;
  font-size: 100px;
}

.form {
  margin-bottom: 10px;
}
