@import './src/styles/main';

.wrapper {
  .name {
    font-size: 24px;
    font-weight: 400;
  }

  .editIcon {
    margin: 0px 40px;
    cursor: pointer;
  }

  .nameWrapper {
    display: flex;
    width: 380px;
    height: 40px;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 16px;
  }

  .textField {
    width: 256px;
  }

  .error {
    margin-top: 6px;
    color: $error;
  }

  .buttonWrapper {
    display: flex;
  }

  .updateButton {
    width: 68px;
    height: 40px;
    margin-left: 20px;
  }

  .cancelButton {
    width: 108px;
    height: 40px;
    margin-left: 8px;
  }

  .ellipse {
    margin: auto 0px;
    margin-right: 16px;
  }

  .roleWrapper {
    display: flex;
    margin-bottom: 64px;
  }

  .typography {
    overflow: hidden;
    font-size: 16px;
    font-weight: 400;
    text-overflow: ellipsis;
  }

  .emailWrapper {
    display: flex;
    width: 500px;
    padding: 16px 12px 16px 40px;
    border: 1px solid $light-gray;
    border-radius: 20px;
  }

  .emailTitle {
    min-width: 116px;
    margin-right: 28px;
    font-size: 16px;
    font-weight: 700;
  }
}
