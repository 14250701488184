$header-height: 40px;
$info-height: 127px;
$footer-height: 72px;
$receipt-width: 450px;
$table-width: 400px;
$table-padding: 20px;
$memo-width: 350px;
$below-header-margin: 20px;
$header-title-gap-margin: 8px;

@mixin MainFlexLeft {
  flex: 0 0 $receipt-width;
}

@mixin MainFlexRight {
  flex: 1 1 $table-width;
}

// category
$category-border-color: #e7e6e4;
$category-border-size: 1px;

// border
$box-border-color: #e7e6e4;
$panel-border-color: $white;
$pane-border-size: 1px;
$header-side-border-size: 1px;

// color
$table-color: $white;
$header-footer-color: #f2f2f2;
$main-column-color: $white;
$report-message-color: #edebe8;
$report-message-border-color: #d6d3d0;
