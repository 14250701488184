@import './src/styles/color';

.menuList {
  width: 228px;
  max-height: 120px;
  padding: 10px;
  border: 1px solid $black200;
  border-radius: 16px;
  margin-bottom: 10px;
  background-color: $white;
}

.item {
  width: 228px;
  height: 44px;
  padding: 8px 10px;
  border-radius: 10px;

  &:not(:last-child) {
    margin-bottom: 8px;
  }
}

.menuItemBox {
  display: flex;
  align-items: center;
}

.obliqueLine {
  margin-right: 4px;
  margin-left: 4px;
}

.badge {
  padding: 0px 6px;
}
