@import './src/styles/main';
@import '../../variables';

.filterWrapper {
  position: sticky;
  z-index: 1;
  top: 0px;

  // NOTE: Update画面にはsidebarがないため
  // FIXME: width: 100vwで指定をすると、画面を小さくしたときにテーブル領域から要素がはみ出てしまう
  min-width: $table-min-width + $sidebar-width;
  background-color: $white;
}

.title {
  margin-top: 44px;
  font-size: 24px;
}

.tableContainer {
  margin-top: 44px;
}

.mainContent {
  margin-bottom: 200px;
}

.mainContent table thead {
  position: sticky;
  z-index: 1;
  top: 64px;
}

.modal {
  width: 800px;
}
.bottom {
  position: fixed;
  bottom: 0;
  width: calc(100% - 50px);
  padding: 8px;
  border-top: 1px solid $light-gray;
  background-color: white;
  text-align: right;
  button {
    margin-right: 20px;
  }
}

.duplicateList {
  margin-left: 20px;
  font-size: 1.2rem;
}
