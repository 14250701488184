@import './src/styles/main';
@import '../../variables';

.registerHeader {
  height: $header-height;
}

.textAndSelectBox {
  display: flex;
  width: 100%;
  align-self: center;
  justify-content: flex-start;
  line-height: $header-height;
}

.number {
  width: 100px;
  margin-left: 30px;
  font-weight: 600;
}

.registerHeaderNumber {
  line-height: 20px;
}
.reportTitle {
  font-size: 14px;
  font-weight: 700;
}
.reportNumber {
  margin-left: 8px;
  font-size: 14px;
  font-weight: 400;
}
.reportUnit {
  margin-left: 2px;
  color: rgb(0, 0, 0, 30%);
  font-size: 14px;
  font-weight: 400;
}
.selectContainer {
  padding-top: calc($header-height / 2 - 18px);
}

.selectBox {
  width: 250px !important;
}

.onlyTextArea {
  margin-left: 30px;
  font-size: 14px;
  font-weight: 700;
  line-height: $header-height;
  text-align: left;
  white-space: nowrap;
}

.reportDate {
  margin-left: $header-title-gap-margin;
  font-size: 14px;
  font-weight: 400;
}
