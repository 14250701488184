@import './src/styles/main';

.form {
  display: flex;
}

.formContainer {
  display: flex;
  height: 100%;
  flex-direction: row;
  align-items: center;
  gap: 16px;
}

.editCalculationMethod {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
}

.label {
  display: flex;
  white-space: nowrap;
}

.roundingTypeOptions {
  width: 112px;
  border-radius: 10px;
}

.precision {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
}

.precisionOptions {
  width: 76px;
  border-radius: 10px;
}

.example {
  font-size: 16px;
}

.buttonWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
}

.updateButton {
  width: 64px;
  height: 36px;
  font-weight: 500;
}

.cancelButton {
  width: 112px;
  height: 36px;
  font-weight: 500;
}

.displayCalculationMethod {
  display: flex;
  height: 100%;
  flex-direction: row;
  align-items: center;
  gap: 8px;
}

.value {
  font-size: 14px;
}

.editWrapper {
  display: flex;
  align-items: center;
  color: blue;
  cursor: pointer;
}

.editIcon {
  padding: 0;
}

.editDescription {
  font-size: 14px;
}
