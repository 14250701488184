@import './src/styles/main';

.container {
  display: flex;
  height: 3rem;
  flex-flow: row wrap;
  align-items: center;
  justify-content: flex-start;
  background-color: $white;

  > *:not(:first-child) {
    margin-left: 8px;
  }

  .count {
    margin-right: 12px;
    font-size: 16px;
  }

  .check {
    display: flex;
    align-items: center;
  }

  .label {
    cursor: pointer;
    font-size: 13px;
    font-weight: 500;
    -webkit-user-select: none; // Fix for Safari
    user-select: none;
  }

  .clear {
    color: $green;
    cursor: pointer;
    -webkit-user-select: none; // Fix for Safari
    user-select: none;
  }
}
