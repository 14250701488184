@import './src/styles/main';

$main-width: 240px;

.paper {
  // NOTE: menu buttonのwidth分を引いている
  width: $main-width - 40px;
  box-sizing: border-box;
  padding: 8px;
  border-radius: 8px;
}

.disabled {
  background-color: $gray03;
  box-shadow: none;
  color: $light-gray03;
}

.title {
  width: 100px;
  font-size: 15px;
  font-weight: 700;
}

.item {
  padding: 8px;
  border-radius: 8px;
}

.item:not(:last-child) {
  margin-bottom: 8px;
}

.listItem {
  height: 36px;
}

.listIcon {
  min-width: initial;
}

.icon {
  width: 20px;
  height: 20px;
}

.root {
  display: flex;
  height: 44px;

  .menu {
    display: grid;
    grid-template-columns: 1fr 2fr 2fr;
  }

  .button {
    padding: 0 12px;
    border-radius: 8px;
    background: $primary;
    color: $white;
  }

  .button:hover {
    background: $primary;
    box-shadow: none;
  }

  .button:active {
    @include _hoverColor($primary);
  }

  .toggleButton {
    width: 40px;
    min-width: unset;
    margin-right: 4px;
  }

  .toggleIcon {
    height: 8px;
    transition: 500ms;
  }

  .mainButton {
    width: $main-width;
  }

  .opened {
    transform: rotate(-90deg);
  }

  .icon {
    width: 20px;
    height: 20px;
  }

  .disableIcon path {
    fill: $black200;
  }

  .unableIcon path {
    fill: $white;
  }

  .unableTitle {
    color: $white;
  }

  .disableTitle {
    color: $black200;
  }

  .selectedItem {
    background-color: $gray01;
  }

  .selectedItem:hover {
    background: $gray01;
  }

  .disabled {
    background-color: $gray03;
    box-shadow: none;
    color: $light-gray03;
  }

  .count {
    font-size: 14px;
    font-weight: 400;
  }
}
