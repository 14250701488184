@import './src/styles/main';
@import '../../variables';

$content-margin: 30px;
$min-memo-width: 284px;

.root {
  position: relative;
  width: 100%;
  min-width: $receipt-width + $table-width + $table-padding * 2 + $memo-width; // 角領域の足し算を最小値に置く。
  height: 100vh;
  overflow-y: auto;
}

.header {
  position: fixed;
  z-index: 10;
  top: 0px;
  display: flex;
  width: 100%;
  height: $header-height;
  align-items: center;
  border-bottom: solid $pane-border-size $panel-border-color;
  background-color: $header-footer-color;
}

.closeButtonWrapper {
  position: absolute;
  top: 0px;
  right: 0px;
  margin-top: 8px;
  margin-right: 8px;
}

.closeButton {
  width: 25px;
  height: 25px;
}

.body {
  display: flex;
  margin-top: $header-height;
}

@mixin MainColumnFlex {
  flex: 1 1 ($receipt-width + $table-width + $table-padding * 2); // main columnを構成する下位componentの幅を足し合わせる。
}

@mixin RightSideBarFlex {
  flex: 0 20 min(max(20vw, $min-memo-width), $memo-width);
}

.bodyMainColumn {
  padding-bottom: 4px;
  background-color: $main-column-color;

  @include MainColumnFlex;
}

.headerMainColumn {
  display: flex;

  @include MainColumnFlex;
}

.headerMainLeft {
  @include MainFlexLeft;
}

.headerMainRight {
  @include MainFlexRight;
}

.bodyRightSideBar {
  z-index: 2;
  border-left: solid $pane-border-size $panel-border-color;
  background-color: $white;

  @include RightSideBarFlex;
}

.headerRightSideBar {
  @include RightSideBarFlex;
}

.stickyBar {
  position: -webkit-sticky;
  position: sticky;
  top: $header-height;
  height: calc(100vh - $header-height - $footer-height - $info-height);
}

.mainContentList {
  width: 100%;

  &:not(:last-child) {
    padding-bottom: $content-margin;
  }
}

.footerBar {
  position: fixed;
  z-index: 20;
  bottom: 0;
  width: calc(100vw - $receipt-width);
  height: $footer-height;
  box-sizing: border-box;
  border-top: solid $pane-border-size $panel-border-color;
  border-left: solid $pane-border-size $panel-border-color;
  margin-left: $receipt-width + 2px;
  background-color: $header-footer-color;

  @media (width <= $breakpoint-windows) {
    height: $footer-height * 0.8;
  }
}
