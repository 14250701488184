@import './src/styles/main';

.root {
  height: 240px;
}

.textarea {
  // FIXME: textareaのwidthを100%にしてしまうと、rootのDOMを超えて突き出てしまうため、暫定対応
  width: 97%;
  height: 200px;
  padding: 8px;
  border: 1px solid $light-gray;
  border-radius: 8px;
  font-family: inherit;
  outline: none;
  resize: none;
  &:focus {
    background-color: $light-gray02;
  }
}

.counter {
  color: $gray03;
  font-size: 14px;
  font-weight: 500;
  & > span {
    margin-left: 2px;
    font-size: 10px;
    font-weight: 600;
  }
}

.container {
  display: flex;
  justify-content: space-between;
}

.errorMessage {
  margin-top: 2px;
  color: $error;
}

.error {
  border: 1px solid $error;
}
