@import './src/styles/main';

.registerHeader {
  height: 40px;
}

.textAndSelectBox {
  display: flex;
  width: 100%;
  align-self: center;
  justify-content: flex-start;
  line-height: 40px;
}

.number {
  width: 100px;
  font-weight: 600;
}

.registerHeaderNumber {
  line-height: 20px;
}
.reportTitle {
  font-size: 14px;
  font-weight: 700;
}
.reportNumber {
  margin-left: 8px;
  font-size: 14px;
  font-weight: 400;
}
.reportUnit {
  margin-left: 2px;
  color: rgb(0, 0, 0, 30%);
  font-size: 14px;
  font-weight: 400;
}
.selectContainer {
  padding-top: calc(40px / 2 - 18px);
}

.selectBox {
  width: 230px !important;
}

.onlyTextArea {
  margin-left: 30px;
  font-size: 14px;
  font-weight: 700;
  line-height: 40px;
  text-align: left;
  white-space: nowrap;
}

.reportDate {
  margin-left: 8px;
  font-size: 14px;
  font-weight: 400;
}

.numberRightMargin {
  margin-right: 228px;
}
