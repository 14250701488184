@import './src/styles/main';
@import '../../variables';

.root {
  width: 100%;
  height: 100%;
  overflow-x: auto;
}

.table {
  width: 100%;
  margin-bottom: 150px;
  background-color: $table-color;
}

.allFiltered {
  margin-top: 20px;
}

.allFilteredText {
  font-weight: 500;
}

.guide {
  svg {
    width: 80px;
    height: 80px;
  }
}

.collapseRow {
  display: none;
}
