@import './src/styles/color';

.header {
  height: 44px;
  white-space: nowrap;
}

.cell {
  box-sizing: border-box;
  padding: 0 12px;
  border-bottom: none;
  background-color: $gray02;
  &:first-child {
    border-top-left-radius: 10px;
  }
  &:last-child {
    border-top-right-radius: 10px;
  }
  &.hide {
    padding: 0;
    background-color: transparent;
    & + .cell {
      border-top-left-radius: 10px;
    }
  }
}

.cellBox {
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: left;
  span {
    margin-left: 8px;
  }
}

.centerCell {
  justify-content: center !important;
}

.message {
  color: red;
  font-size: 0.8rem;
  font-weight: normal;
}

.labeledMessage {
  padding: 4px;
  border-radius: 8px;
  margin: 0px 4px;
  background-color: red;
  color: white;
  font-size: 0.6rem;
  font-weight: bold;
  line-height: 0.6rem;
}
