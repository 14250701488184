@import './src/styles/main';
@import '../../variables';

.root {
  width: 100%;
}

.wrapper {
  display: flex;
  width: 100%;
  min-height: 644px;
  max-height: 95vh;
}

.receiptWrapper {
  padding-top: 4px;
  overflow-y: scroll;

  @include MainFlexLeft;
}

.pdfWrapper {
  padding-top: 4px;

  @include MainFlexLeft;
}

.pdf {
  width: 100%;
  height: 100%;
}

.receipt {
  width: 100%;
  height: 100%;
}

.tableWrapper {
  padding-left: $table-padding;
  overflow-x: auto;

  @media (width <= $breakpoint-windows) {
    padding-left: $table-padding * 0.9;
  }

  @include MainFlexRight;
}

.topMargin {
  margin-top: $below-header-margin;
}

.table {
  border-radius: 10px;

  @media (width <= $breakpoint-windows) {
    table {
      transform: scale(0.9);
      transform-origin: top left;
    }
  }
}

.tenantRegister {
  display: flex;
  height: 40px;
  align-items: center;
  gap: 4px;

  .title {
    height: 24px;
    font-size: 18px;
    font-weight: 700;
  }

  .number {
    height: 24px;
    font-size: 18px;
    font-weight: 400;
  }

  .badge {
    height: 20px;
    padding: 1px 6px;
    margin-left: 8px;
  }
}
