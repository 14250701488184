@import './src/styles/main';

.box {
  display: flex;
  height: 398px;
  align-items: center;
  justify-content: center;
  border-radius: 16px;
  background: $light-gray00;
}

.wrapper {
  text-align: center;

  .svg {
    width: 42px;
    height: auto;
    margin-bottom: 25px;
  }

  .message {
    margin-bottom: 8px;
  }

  .description {
    color: $black500;
    line-height: 20.8px;
    white-space: pre-wrap;
  }
}
