@import './src/styles/main';
@import '../../variables';

.root {
  margin: 0px 8px;
}

.eachCategory {
  padding: 10px 0px;
  margin: 0px 10px;
}

.title {
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
}

.category {
  font-size: 14px;
  font-weight: 700;
}

.content {
  margin-left: $header-title-gap-margin;
  font-size: 14px;
  font-weight: 400;
}
