@import './src/styles/main';
@import '../../variables';

.title {
  margin-top: 20px;
  margin-bottom: 20px;
  font-size: 24px;
}

.mainContent {
  width: 700px;
  margin-bottom: 200px;
}

.bottom {
  position: fixed;
  bottom: 0;
  width: 100%;
  padding: 10px;
  border-top: 1px solid $light-gray;
  background-color: $white;
  text-align: right;
  button {
    margin-right: 20px;
  }
}
