@import './src/styles/color';

.row {
  height: 60px;
  background-color: $white;
  cursor: default;

  &.disabled {
    background-color: $light-gray;
  }
}
