.chip {
  height: auto;
  padding: 4px 6px;
  border: 1px solid;
  border-radius: 4px;
}

.ellipseChip {
  border-radius: 16px;
}

.smallChip {
  padding: 0px 6px;
}

.label {
  padding: 0;
  margin-top: 1px;
  font-size: 13px;
  font-weight: 500;

  &.smallLabel {
    font-size: 11px;
  }
}
