@import './src/styles/main';

.root {
  position: relative;
  display: flex;
  overflow: scroll;
  width: 230px;
  height: 100%;
  flex-direction: column;
  justify-content: space-between;
}

.img {
  position: absolute;
  z-index: -1;
  width: 100%;
  object-fit: contain;
  transition-duration: 500ms;
}

a {
  width: 100%;
  height: 100%;
  &:hover {
    cursor: pointer;
  }
}

.grabbing {
  cursor: grabbing;
}

.grab {
  cursor: grab;
}
