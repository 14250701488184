@import './src/styles/main';

.wrapper {
  .breadcrumbs {
    height: $breadcrumb-height;
  }

  .title {
    font-size: 24px;
    font-weight: 700;
  }
}
