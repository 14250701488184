.root {
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
}

.typography {
  overflow: hidden;
  width: 100%;
  margin-left: 12px;
  cursor: default;
  font-size: 12px;
  font-weight: 500;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.typoWrapper {
  width: 140px;
}

.organization {
  display: flex;
  height: 36px;
  align-items: center;
  border-radius: 4px;
  margin-bottom: 8px;
  font-size: 16px;
  font-weight: 700;
}

.bottom {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.button {
  width: 36px;
  height: 36px;
  margin-left: 8px;
}
