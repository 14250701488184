@import './src/styles/main';

.root {
  .listItem {
    width: 100%;
    border-radius: 8px;
  }

  .listItem:hover {
    background-color: $black50;
  }

  .title {
    font-size: 16px;
    font-weight: 400;
  }

  .svgIcon {
    width: 24px;
    fill: black;
  }

  .listItem:hover .svgIcon {
    fill: $black;
  }

  .selectedItem {
    background-color: $black50;
    color: $primary;
  }

  .listItemText {
    height: 28px;
    margin-left: 8px;
  }

  .selectedTitle {
    font-weight: 700;
  }

  .badge {
    margin-left: 8px;
  }
}
