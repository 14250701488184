@import './src/styles/main';
@import '../../variables';

$left-width: 90px;
$right-width: 250px;
$ng-check-width: 170px;
$progress-text-width: 40px;
$container-padding-side: 16px;

.footer {
  display: flex;
  padding: 16px $container-padding-side 16px;
  background-color: $white;

  @media (width <= $breakpoint-windows) {
    padding: 12px $container-padding-side 12px;
  }
}

.footerLeft {
  width: $left-width;
  justify-content: flex-start;
}

.footerRight {
  display: flex;
  width: $right-width;
  justify-content: flex-end;
}

.button {
  height: 100%;
  border-radius: 8px;
  white-space: nowrap;

  @media (width <= $breakpoint-windows) {
    font-size: 14px;
  }
}
.whiteButton {
  border: 1px solid $box-border-color;
  background-color: $white;
}

.skipButton {
  margin-right: 16px;
}

.footerCenter {
  display: flex;
  width: calc(100% - $left-width - $right-width - $container-padding-side * 2);
  flex: 1;
  align-items: center;
  justify-content: center;
}

.progress {
  width: calc(100% - $ng-check-width - $progress-text-width);
}

.progressText {
  width: $progress-text-width;
  padding-left: 10px;
}

.ngCheck {
  width: $ng-check-width;
}

.ngCheckText {
  span {
    font-size: 16px;

    @media (width <= $breakpoint-windows) {
      font-size: 12px;
    }
  }
}
