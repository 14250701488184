@import './src/styles/main';

.root {
  .header {
    z-index: 3;
    padding-bottom: 20px;
    background-color: $white;
  }

  .accordion {
    margin-bottom: 20px;
  }

  .content {
    display: flex;
    height: 555px;
    margin-top: 4px;

    .receiptTableWrapper {
      display: flex;
      width: 100%;
      margin-right: 20px;
      .receiptWrapper {
        flex: 0 1 400px;
        padding-top: 4px;
        margin-right: 20px;

        .receipt {
          width: 100%;
          height: 555px;
        }
      }

      .tableWrapper {
        display: flex;
        width: 100%;
        flex: 1 1 500px;

        .table {
          width: 100%;
        }
      }
    }

    .messages {
      flex: 1 1 280px;
    }
  }

  .guide {
    width: 100%;
  }

  .listDivider {
    margin: 40px 0 16px;
  }
}
