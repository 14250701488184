.root {
  display: grid;
  align-items: center;
}

.ol {
  height: 100%;
  line-height: 14px;
}

.typography {
  font-weight: 700;
  line-height: 14px;
}

.svgIcon {
  width: 14px;
  height: 14px;
}

.link {
  cursor: pointer;
}
