@import './src/styles/main';

.root {
  width: 8px;
  height: 8px;
}

.red {
  & * {
    fill: $vermilion;
  }
}

.green {
  & * {
    fill: $green02;
  }
}

.gray {
  & * {
    fill: $unavailable;
  }
}
