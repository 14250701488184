@import './src/styles/main';

.select {
  display: flex;
  width: 160px;
  height: 36px;
  box-sizing: border-box;
  align-items: center;
  border: 1px solid;
  border-radius: 4px;
  background: $white;
  font-size: 13px;
  &:hover {
    background: $light-gray02;
  }
  fieldset {
    border: none;
  }

  & .MuiInput-input {
    margin-right: 0;
    & * {
      overflow: hidden;
      margin: 0 12px;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  .icon {
    top: unset;
    right: 12px;
    transition: 225ms; // Menu が開くときのアニメーションと同じ

    &.primary {
      fill: $black;
    }
    &.green {
      fill: $green;
    }
    &.red {
      fill: $red;
    }
    &.gray {
      fill: $gray03;
    }
  }

  &.primary {
    border-color: $light-gray;
    color: $black;
  }
  &.green {
    border-color: $green;
    color: $green;
  }
  &.red {
    border-color: $red;
    color: $red;
  }
  &.gray {
    border-color: $gray03;
    color: $gray03;
  }
}

.menu {
  box-sizing: border-box;
  padding: 0;
  border-radius: 6px;
  box-shadow: 0px 4px 14px $black100;

  .option {
    padding: 12px 16px;
  }

  .selected {
    background-color: $light-gray;
  }
}
