@import './src/styles/color';

.items {
  max-height: 250px;
  padding: 8px 16px 4px 8px;
  border: 2px solid $light-gray;
  border-radius: 4px;
  margin: 4px;
  background-color: white;
  list-style: none;
  overflow-y: scroll;
}

.item {
  padding: 0.4rem;
  border-bottom: solid $gray03;
  &:hover {
    background-color: $light-gray02;
  }
}

.item:last-child {
  border-bottom: none;
}
