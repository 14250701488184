@import './src/styles/main';
@import '../../variables';

.tenantTypography {
  display: flex;
  padding-left: 20px;
  > p {
    font-size: 16px;
    font-weight: 700;
    line-height: 35px;
    line-height: $header-height;
    &.code {
      margin-right: $header-title-gap-margin;
      color: $gray03;
    }
    &.name {
      max-width: calc(100vw - $receipt-width - $memo-width - 140px);

      @include ellipsis;
    }
  }
}
