@import './src/styles/main';

.listItem {
  height: 72px;
  padding: 0 16px 0 14px;

  &:hover {
    background-color: $black50;
  }
}

.black {
  color: $black;
}

.vermilion {
  color: $vermilion;
}

.unavailable {
  color: $unavailable;
}

.ready {
  color: $black;
}

.not_ready {
  color: $vermilion;
}

.closed {
  color: $unavailable;
}

.ellipseIcon {
  margin-right: 6px;
}

.content {
  display: grid;
  width: 100%;
  align-items: center;
  grid-template-columns: 2fr 3fr;
}

.opaContent {
  display: grid;
  width: 100%;
  align-items: center;
  grid-template-columns: 1fr 3fr;
}

.tenantIcon {
  min-width: 0;
  margin-right: 26px;
}

.store {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.tenantNameAndCodeContent {
  display: flex;
}

.tenantName {
  overflow: hidden;
  max-width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.tenantCode {
  margin-right: 12px;
  font-weight: 500;
}

.description {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
}

.opaDescription {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
}

.text {
  text-align: left;
}
