.root {
  position: relative;
  border-radius: 50%;
  text-align: center;
}

.icon {
  position: absolute;
  margin: auto;
  inset: 0 auto;
  transform: translateX(-50%);
}
