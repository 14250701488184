@import './src/styles/main';

.root {
  height: 100%;
  margin-bottom: 34px;

  .guide {
    margin-top: 8px; // 22 - 14
  }

  .header {
    position: sticky;
    position: -webkit-sticky; // Add this line to support Safari on iOS 7+
    z-index: 1;
    padding-top: 18px;
    margin-bottom: 14px;
    background-color: $white;

    .buttonWrapper {
      display: flex;
      justify-content: right;
      margin-bottom: 12px;

      .button {
        &:first-child {
          margin-right: 8px;
        }
      }
    }
  }

  .divider {
    margin-top: 5px;
    background-color: $light-gray;
  }
}
